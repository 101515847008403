import {Modal} from "antd";

export const GdprInfo = ({visible, setVisible}) => {
    return (
            <Modal
                centered={true}
                cancelButtonProps={{ style: { display: 'none' } }}
                title="Privacy Policy"
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={1000}
            >
                <p>
                    Fulfilling the information obligation pursuant to Article 13 sec. 1 and 2 of the Regulation of the
                    European Parliament and Council (EU) 2016/679 of 27 April 2016 on the protection of natural persons
                    with regard to the processing of personal data and on the free movement of such data, and repealing
                    Directive 95/46/EC (General Data Protection Regulation) [OJ EU L. 2016.119.1 of 4 May 2016],
                    hereinafter referred to as the "GDPR", we hereby inform that:
                </p>
                <ol>
                    <li>The Controller of your personal data is the AGH University of Science and Technology in Cracow,
                        al. A. Mickiewicza 30, 30-059 Cracow.
                    </li>
                    <li>Contact details of the Inspector for Personal Data Protection at the AGH University of Science
                        and Technology: Al. Mickiewicza 30, 30-059 Krakow, email: <a href="mailto:iodo@agh.edu.pl">iodo@agh.edu.pl</a>, tel.: 12 617 53 25;
                    </li>
                    <li>Your personal data provided in the registration form are subject to processing.</li>
                    <li>Your personal data shall be processed to organise and hold the conference, including
                        communications sent in connection with the participation in the conference.
                    </li>
                    <li>Your personal data shall be processed in compliance with Article 6 sec. 1(b) GDPR - the
                        processing is necessary to execute the agreement, whose subject is the registration and
                        participation in the conference.
                    </li>
                    <li>The recipients of your personal data may be entities with whom we collaborate while organizing
                        the conference.
                    </li>
                    <li>Your personal data may be stored for the time period necessary to properly organize and settle
                        the conference - 5 years counting from the end of the calendar year, in which the conference was
                        organized.
                    </li>
                    <li>You have the right of access, rectification and limitation of the processing of your personal
                        data in cases and according to the terms and conditions stipulated in the GDPR.
                    </li>
                    <li>You have the right to submit a complaint with the President of Personal Data Protection Office
                        if you decide that the processing of your personal data breaches the provisions of the GDPR.
                    </li>
                    <li>Your personal data shall not be subject to automatic decision-making processes, including
                        profiling.
                    </li>
                    <li>The provision of your personal data is voluntary, but necessary to execute the agreement, whose
                        subject is the registration and participation in the conference. Failure to provide your
                        personal data shall inability to participate in the above-mentioned conference.
                    </li>
                </ol>
            </Modal>
    )

}

