import css from 'styled-components'
import config from "../../config";
import {Link} from "react-router-dom";

const Title = css.div`
  position: relative;
  z-index: 10;
  text-shadow: 0 1px 0 #999, 0 2px 0 #888, 0 3px 0 #777, 0 4px 0 #666, 0 5px 0 #555, 0 6px 0 #444, 0 7px 0 #333, 0 8px 7px #001135;
  color: #ffffff;
  font-size: 3.5em;
  font-family: "Arial Black", "Verdana font-weight:bold", sans-serif;
  font-weight: 900;
  cursor: pointer;
  user-select: none;
  
  a {
    color: inherit;
    text-decoration: inherit;
  }

  @media only screen and (max-width: 576px) {
    font-size: 2em;
    text-align: center;
  }

`
const SubTitle = css(Title)`
  font-size: 1.7em;
`


export const SiteLogo = () => {
    const {title, subTitle} = config

    return (
        <>
            <Title>
                <Link to={'/'}>{title}</Link>
            </Title>
            <SubTitle>
                <Link to={'/'}>{subTitle}</Link>
            </SubTitle>
        </>
    )
}
