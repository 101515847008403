import {API_GET_PUBLIC_SETTINGS,} from "../constants/action-types";

const initialState = {
    sessions: []
};

const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${API_GET_PUBLIC_SETTINGS}_SUCCESS`:
            return {
                ...state,
                sessions: action.payload.data.sessions
            }
        default:
            return state;
    }
}

export default settingsReducer;
