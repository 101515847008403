import "./wdyr";
import React from "react";
import * as ReactDOMClient from 'react-dom/client';

import "dayjs/locale/en";
import { hydrateRoot } from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
import AppLoader from "./AppLoader";
// import "./services/sentry"
const root = ReactDOMClient.createRoot(document.getElementById('root'));

// Initial render: Render an element to the root.
root.render(<AppLoader />);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
