import {Redirect, Route, useLocation} from "react-router-dom";
import jwtDecode from "jwt-decode";
import React from "react";
import {useSelector} from "react-redux";

export const AuthRoute = ({roles, component, path, ...rest}) => {
    const location = useLocation();
    const auth = useSelector(state => state.auth.token)

    // If role is not required
    if (!roles) return <Route path={path} component={component}/>;

    // If user is authenticated
    if (auth.token) {
        // if main site -> redirect to profile
        if (rest.path === "/") return <Redirect to="/profile"/>;

        // checks permission
        const tokenData = jwtDecode(auth.token);
        const canAccess = roles.includes(tokenData.role);
        const Component = component
        if (canAccess) return <Route path={path}><Component/></Route>;
    }

    // No authenticated
    return <Redirect to={`/?redirect=${location.pathname}`}/>;
};
