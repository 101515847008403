import React from 'react';
import {Menu} from "antd";
import {Link, useLocation} from "react-router-dom";
import {ROLE_ADMIN, ROLE_EDITOR, ROLE_REVIEWER} from "../constants/strings";
import If from "babel-plugin-jsx-control-statements"
import config from '../config'
import {FormOutlined, PoweroffOutlined, ReadOutlined, UserOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import styled from 'styled-components'

const SubMenu = Menu.SubMenu;
const {feeTabVisible, paperTabVisible} = config

const ImportantEntry = styled.a`
  font-weight: 700;

  &:visited {
    color: #254586;
  }
`

const AppMenu = () => {
    const location = useLocation()
    const {role, canSubmitPaper} = useSelector(state => state.auth.user);

    const openedKeys = ['author', 'reviewer', 'editor', 'admin'];
    if (['/abstracts', '/abstracts/add'].includes(location.pathname)) {
        openedKeys.push('abstracts')
    } else if (['/papers', '/papers/add'].includes(location.pathname)) {
        openedKeys.push('papers')
    }

    return (
        <Menu
            mode="inline"
            // defaultSelectedKeys={['/abstracts']}
            defaultOpenKeys={openedKeys}
            style={{height: '100%'}}
            selectedKeys={[location.pathname]}
        >
            <SubMenu key="author" icon={<UserOutlined/>} title="Author">
                <If condition={feeTabVisible}>
                    <Menu.Item key="/fee">
                        <Link to='/fee'>Fee</Link>
                    </Menu.Item>
                </If>
                <Menu.Item key="/documents">
                    <Link to='/documents'>Documents</Link>
                </Menu.Item>

                <Menu.Item key="/abstracts">
                    <Link to='/abstracts'>Abstracts</Link>
                </Menu.Item>

                {/*<Menu.Item key="/schedule">*/}
                {/*    <ImportantEntry target='_blank' rel="noopener noreferrer" href='https://kpt2021.suberus.app/'>Schedule</ImportantEntry>*/}
                {/*</Menu.Item>*/}

                <If condition={paperTabVisible}>
                    <SubMenu key='papers' title='Papers'>
                        <Menu.Item key="/papers">
                            <Link to='/papers'>{config.menu.papersList}</Link>
                        </Menu.Item>
                        <Menu.Item key="/papers/add" disabled={!canSubmitPaper}>
                            <Link to='/papers/add'>{config.menu.submitPaper}</Link>
                        </Menu.Item>
                    </SubMenu>
                </If>
            </SubMenu>
            <If condition={role === ROLE_REVIEWER || role === ROLE_ADMIN}>
                <SubMenu key="reviewer" icon={<ReadOutlined/>} title="Reviewer">
                    <Menu.Item key="/reviews/abstracts">
                        <Link to='/reviews/abstracts'>Abstracts Reviews</Link>
                    </Menu.Item>

                    <If condition={paperTabVisible}>
                        <Menu.Item key="/reviews/papers">
                            <Link to='/reviews/papers'>Papers Reviews</Link>
                        </Menu.Item>
                    </If>
                </SubMenu>
            </If>
            <If condition={role === ROLE_EDITOR}>
                <SubMenu key="editor" title="Editor" icon={<FormOutlined/>}>
                    <Menu.Item key="/editor/papers">
                        <Link to='/editor/papers'>Papers</Link>
                    </Menu.Item>
                </SubMenu>
            </If>
            <If condition={role === ROLE_ADMIN}>
                <SubMenu key="admin" title="Administrator" icon={<PoweroffOutlined/>}>
                    <Menu.Item key="/editor/dashboard">
                        <Link to='/editor/dashboard'>Dashboard</Link>
                    </Menu.Item>
                    <Menu.Item key="/editor/users">
                        <Link to='/editor/users'>Participants</Link>
                    </Menu.Item>
                    <Menu.Item key="/editor/abstracts">
                        <Link to='/editor/abstracts'>Abstracts</Link>
                    </Menu.Item>
                    <If condition={paperTabVisible}>
                        <Menu.Item key="/editor/papers">
                            <Link to='/editor/papers'>Papers</Link>
                        </Menu.Item>
                    </If>
                    <Menu.Item key="/editor/mailing">
                        <Link to='/editor/mailing'>Mailing</Link>
                    </Menu.Item>
                    <Menu.Item key="/editor/settings">
                        <Link to='/editor/settings'>Settings</Link>
                    </Menu.Item>
                </SubMenu>
            </If>
        </Menu>
    );
}


export default AppMenu;
