import {If} from "babel-plugin-jsx-control-statements";
import {Alert, Button, Col, Grid, Row} from "antd";
import {useCookies} from "react-cookie";
import EuFlag from "./EuFlag.jpg"
import {GdprInfo} from "./GdprInfo";
import {useState} from "react";
import styled from 'styled-components'
const { useBreakpoint } = Grid;

const StyledAlert = styled(Alert)`
  width: 100%;
  z-index: 100;
  border: none;
  background-color: #f7f7f7;
  margin-top: 0;
  box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
  border-radius: 5px;
`

const AlertContent = styled.div`
  display: flex;
`

const AlertText = styled.div`
  flex: 1;
  font-size: 0.9em;
`

const AlertButton = styled(Button)`
  padding: 0 0 0 5px;
  font-size: 1em;
`

const FlagContainer = styled.div`
  width: 120px;
`

export const GdprAlert = () => {
    const [cookies, setCookie] = useCookies(['gdprAccepted']);
    const [privacyVisible, setPrivacyVisible] = useState(false);
    const screens = useBreakpoint();

    const isMobile = screens['xs'];

    return (
        <If condition={!cookies.gdprAccepted}>
            <Row justify="center">
                <Col xs={24} md={12} xl={9}>
                    <div style={{  width: '100%', margin: "0 auto 0 auto"}}>
                        <StyledAlert
                            description={
                                <AlertContent>
                                    <If condition={!isMobile}>
                                    <FlagContainer>
                                        <img alt='EU flag' src={EuFlag} style={{width: 100}}/>
                                    </FlagContainer>
                                    </If>
                                    <AlertText>
                                        We process personal information in accordance with EU laws. As part of your registration, you agree to this. You should read the policy carefully, especially if you have any concerns about your privacy.
                                         <AlertButton size="small" type="link" onClick={() => setPrivacyVisible(true)}>See privacy policy</AlertButton>.
                                    </AlertText>

                                </AlertContent>
                            }
                            type="info"
                            closable
                            onClose={() => {
                                setCookie('gdprAccepted', true, {
                                    maxAge: 60 * 60 * 24 * 365      // 1 year
                                })
                            }}
                            message={null}/>
                    </div>
                </Col>
            </Row>
            <GdprInfo visible={privacyVisible} setVisible={setPrivacyVisible}/>
        </If>
    )
}