import React from 'react'
import {Button} from "antd";

export default {
    name: 'numiform2023',
    title: 'Numiform 2023',
    subTitle: 'The 14-th International Conference on Numerical Methods in Industrial Forming Processes',
    conferenceSiteUrl: 'https://www.numiform2023.agh.edu.pl/',
    registrationFormText: (<span>Complete the form below in order to register for the Numiform 2023 Conference in Krakow,
        June 25-29, 2023. If you experience any problems with the site, you may wish to try another browser.
        If you continue to have problems, please contact us at <a
            href="mailto:numiform2023@agh.edu.pl">numiform2023@agh.edu.pl</a>.</span>),
    feeTabVisible: true,
    paperTabVisible: true,
    backgroundImage: '/background_numiform2023.jpg',
    abstractTemplate: null,
    paperTemplate: null,
    copyrightsForm: null,
    sentryDsn: 'https://179bca37dc92475089165779b4313ff6@sentry.kisim.eu.org/3',
    feeText: (<div style={{textAlign: 'center'}}>
        <p>
            <p>
                <strong>Payments are processed in cooperation with our partner – Jordan Group.</strong><br/>
            </p>
            <p>
                <img src="/jordan.webp" alt="Jordan Group logo" width={300}/>
            </p>
            <p>
                Details and payment methods are available on the <a href="https://numiform2023.agh.edu.pl/main.html?action=fee"
                                                                    target="_blank" rel="noopener noreferrer">Conference
                Website</a>
            </p>
            <p>
                <Button size="large" type="primary"
                        onClick={() => window.open("https://numiform2023.jordan.pl/en/create-account")}>Pay online</Button>
            </p>

            <p>
                If your fee's status does not change within a few days of making the payment,
                please
                contact the conference secretariat: <a href="mailto:barana@agh.edu.pl">Anna
                Smyk (barana@agh.edu.pl)</a>
            </p>
        </p>
    </div>
),
    menu: {
        papersList: 'List of full papers',
        submitPaper: 'Submit full paper'
    },
    fees: [
        {
            id: 'FULL_EARLY',
            label: 'Full early'
        },
        {
            id: 'FULL_LATE',
            label: 'Full late'
        },
        {
            id: 'STUDENT_EARLY',
            label: 'Student early'
        },
        {
            id: 'STUDENT_LATE',
            label: 'Student late'
        },
        {
            id: 'STAFF',
            label: 'Technical staff'
        },
        {
            id: 'INVITED',
            label: 'Invited'
        },
        {
            id: 'CASH',
            label: 'Cash on desk'
        }
    ],
    apiUrl: process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:9000/api',
}
