import styled from "styled-components";
import config from "../../config";
import backIcon from './back.png';

const DivContainer = styled.div`
  //z-index: 10;
  //position: absolute;
  //color: #ffffff;
  //bottom: 5px;
  padding-bottom: 20px;
  //margin-left: -20px;
  
  a {
    color: #fff;
    font-weight: 400;
  }

  a:hover {
    font-weight: 600;
  }

  a:before {
    content: url(${backIcon});
    left: -5px;
    top: 3px;
    position: relative;
  }
`

const {conferenceSiteUrl} = config

export const ConferenceSiteLink = () => {

    return(
        <DivContainer>
            <span><a href={conferenceSiteUrl}>Back to conference website</a></span>
        </DivContainer>
    )

}