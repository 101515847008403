import React, {useState} from "react";
import {Button} from "antd";
import axios from "../services/axios";
import {CloudDownloadOutlined} from "@ant-design/icons";
import styles from './DownloadButton.module.scss'
import {browserDownload} from "../services/download";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import * as Sentry from "@sentry/browser";

const handleDownload = (url, onStart, onFinish, onError = false) => {
    onStart()
    axios({
        method: 'get',
        url,
        responseType: 'blob'
    })
        .then(
            (response) => {
                browserDownload(response);
                onFinish();
            }
        )
        .catch(errInfo => {
            Sentry.captureException(errInfo);

            if(onError)
                onError(errInfo)
        })
}


const DownloadButton = ({url, label, size = "small", type = "link", shape = 'default', icon = <CloudDownloadOutlined/>}) => {
    const [isBusy, setBusy] = useState(false);
    const [isFailed, setFailed] = useState(false)

    const btnIcon = isBusy ? <LoadingOutlined/> : icon

    return (
        <>
            <Button
                onClick={
                    () => handleDownload(url,
                        () => setBusy(true),
                        () => setBusy(false),
                        () => {setFailed(true); setBusy(false)})
                }
                className={type === 'link' ? styles.btn : null}
                disabled={isBusy}
                size={size}
                type={type}
                danger={isFailed}
                icon={btnIcon}
                shape={shape}
            >
                {label}
            </Button>

    </>
    )
}

export default DownloadButton;
