import React from 'react';
import dayjs from "dayjs";
import {StyledTable} from "../lists/Components/StyledTable";
import {NoPaddingCard} from "../lists/Components/NoPaddingCard";
import {StatusBadge} from "../components/StatusBadge";
import {MultilineText} from "../components/MultilineText";

const baseColumns = [
    {
        title: 'Date',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        width: 160,
        render: (text, record, i) => <span>{dayjs(text).format('YYYY-MM-DD HH:mm:ss')}</span>
    },
    {
        title: 'From',
        dataIndex: 'sourceName',
        width: 130,
        key: 'sourceName',
    },
    {
        title: 'Status change',
        dataIndex: 'status',
        key: 'status',
        width: 100,
        render: (text, record, i) => <StatusBadge status={text}/>
    },
    {
        title: 'Comment',
        dataIndex: 'comment',
        key: 'comment',
        render: (text, record, i) => <MultilineText>{text}</MultilineText>
    }
];


const CommentsView = ({data, title = ""}) => {
        return (
            <div>
                <NoPaddingCard title={title}>
                    <StyledTable size='small' rowKey="id" dataSource={data} columns={baseColumns} pagination={false} />
                </NoPaddingCard>
            </div>
        );
}

export default CommentsView;
