import {Col, Layout, Row} from 'antd';
import config from "../config"
import styled from 'styled-components';
import {SiteLogo} from "../components/SiteLogo";
import {ConferenceSiteLink} from "../components/ConferenceSiteLink";
import {If} from "babel-plugin-jsx-control-statements"

const {backgroundImage} = config

const Background = styled(Layout)`
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${props => props.image}) no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  padding: 0 50px;
  overflow: hidden; // Fixing Rows gap behind screen in mobile

  @media only screen and (max-width: 576px) {
    padding: 0 5px;
  }
`

const FlexCol = styled(Col)`
  align-self: ${props => props.align}
`

const FlexRow = styled(Row)`
  flex: 1;
`

export const BasicLayout = ({children, showConferenceSiteLink = true}) => {
    const alignChildren = showConferenceSiteLink ? "middle" : "top";

    return (
        <Background image={backgroundImage}>
            <FlexRow gutter={[0, 5]} justify="center" align={alignChildren}>
                <FlexCol xs={24} align='flex-start'>
                    <SiteLogo/>
                </FlexCol>

                {children}

                <If condition={showConferenceSiteLink}>
                    <FlexCol xs={24} align='flex-end'>
                        <ConferenceSiteLink/>
                    </FlexCol>
                </If>
            </FlexRow>
        </Background>
    );
}

