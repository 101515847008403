import axios from 'axios';
import config from '../config'

const client = axios.create({
    baseURL: config.apiUrl,
    responseType: 'json'
});

export const setAuthHeaders = (token) => {
    client.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export default client;

