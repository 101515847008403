import React, {Component, useEffect, useState} from 'react';
import If from 'babel-plugin-jsx-control-statements'
import {Button, Tag, Tooltip} from 'antd';
import {Link, withRouter} from "react-router-dom";
import styles from "./Abstracts.module.scss";
import PropTypes from "prop-types"
import {conference} from "../constants/strings";
import EditButton from "./Components/EditButton";
import DeleteButton from "./Components/DeleteButton";
import {StyledTable} from "./Components/StyledTable";
import {NoPaddingCard} from "./Components/NoPaddingCard";
import {RollbackOutlined, FileOutlined} from "@ant-design/icons";

const AddAbstractButton = (props) => {
    return (
        <Link to='/abstracts/add'>
            <Button type="primary" shape="round" icon={<FileOutlined/>}>Submit new abstract</Button>
        </Link>
    )
}

class Abstracts extends Component {

    columns = [{
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (title, record) => (
            <span><Link to={`/abstracts/${record.id}`}>{title}</Link></span>
        )
    },
        {
            title: 'Authors',
            dataIndex: 'authors',
            render: (authors, record) => (<ul className={styles.authorList}>{authors.map((author, authorIndex) => <li
                key={author.id}
                style={{textDecoration: authorIndex === record.presenter ? 'underline' : 'none'}}>{author.lastName} {author.firstName}</li>)}</ul>),
            key: 'author',
            width: 250,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: type => (
                <span>
                {type === 'oral' ? <Tag color="blue">Oral</Tag> : <Tag color="green">Poster</Tag>}
        </span>
            ),
            width: 150,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => (
                <span>
                <Tooltip placement="top" title={conference.abstractStatusTip[status]}>
                    <Tag color={conference.abstractStatusColor[status]}
                         key={status}>{conference.abstractStatus[status]}</Tag>
                </Tooltip>
        </span>
            ),
            width: 150,
        }, {
            title: 'Submission date',
            key: 'submissionDate',
            dataIndex: 'createdAt',
            render: date => <span>{date.substring(0, 10)}</span>,
            width: 150,
        }, {
            title: 'Action',
            key: 'action',
            width: 120,
            render: (text, record) => (
                <div>
                    <If condition={record.status === 'SUBMITTED'}>
                        <EditButton abstract={record}
                                    disabled={!conference.authorCanEdit.some(status => status === record.status)}
                                    action={() => this.props.history.push(`/abstracts/${record.id}/edit`)}/>
                        <DeleteButton abstract={record} action={() => this.props.deleteAbstract(record.id)}/>
                    </If>
                    <If condition={record.status === 'REVISE_REQUIRED' || record.status === 'CONDITIONALLY_ACCEPTED'}>
                        <Button
                            onClick={() => this.props.history.push(`/abstracts/${record.id}/resubmit`)}
                            type="primary"
                            shape="circle"
                            icon={<RollbackOutlined/>}
                        />
                    </If>
                </div>
            ),
        }];


    render() {
        return (
            <div>
                <NoPaddingCard title="Submitted abstracts" extra={<AddAbstractButton/>}>
                    <StyledTable
                        rowKey="id"
                        columns={this.columns}
                        dataSource={this.props.abstracts}
                        pagination={false}
                    />
                </NoPaddingCard>
            </div>
        );
    }
}

Abstracts.propTypes = {
    abstracts: PropTypes.array,
    deleteAbstract: PropTypes.func.isRequired
};
Abstracts = withRouter(Abstracts)

export default Abstracts;
