import React from 'react';
import {BasicLayout} from "../views/BasicLayout";
import ResetPasswordForm from "../forms/ResetPassword";
import {Col, Row} from "antd";
import {Panel} from "../components/Panel";


const ForgotPassword = () => {
    return (
        <BasicLayout>
            <Col xs={24}>
                <Row align='middle' justify='center'>
                    <Col sm={5} xs={24}>
                        <Panel>
                            <ResetPasswordForm/>
                        </Panel>
                    </Col>
                </Row>
            </Col>
        </BasicLayout>
    );
}

ForgotPassword.propTypes = {};
ForgotPassword.defaultProps = {};

export default ForgotPassword;
