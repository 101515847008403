import React, {Component} from 'react';
import RegistrationForm from "../forms/user/Registration";
import {BasicLayout} from "../views/BasicLayout";
import {bindActionCreators} from "redux";
import {suggestCountry, userFormEdit, userRegister} from "../redux-actions/auth";
import {connect} from "react-redux";
import {Col, Row} from "antd";
import {Panel} from "../components/Panel";
import {GdprAlert} from "../components/Gdpr/GdprAlert";

class Registration extends Component {

    componentDidMount() {
        this.props.suggestCountry()
    }

    render() {
        const {userRegister, userFormEdit, formContent, country} = this.props

        return (
            <BasicLayout>
                    <Col xs={24}>
                        <Row align='middle' justify='center'>
                            <Col sm={9} xs={24}>
                                <Panel>
                                            <RegistrationForm userRegister={userRegister} onFormEdit={userFormEdit}
                                                              formContent={formContent} country={country}/>

                                </Panel>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24}>
                        <GdprAlert/>
                    </Col>
            </BasicLayout>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    formContent: state.auth.userForm,
    country: state.auth.country
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        userRegister,
        userFormEdit,
        suggestCountry
    },
    dispatch,
)


Registration = connect(mapStateToProps, mapDispatchToProps)(Registration)
export default Registration;
