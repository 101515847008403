import React, {Component} from 'react';
import BasicLayout from "../../views/MainLayout";
import Papers from "../../lists/Papers";
import {bindActionCreators} from "redux";
import {getPapers} from "../../redux-actions/author";
import {connect} from "react-redux";


class PapersListPage extends Component {

    render() {
        const {papers} = this.props
        return (
            <BasicLayout>
                <Papers papers={papers} urlPrefix='/papers'/>
            </BasicLayout>
        );
    }

    componentDidMount() {
        this.props.getPapers()
    }
}

const mapStateToProps = (state, ownProps) => ({
    papers: state.author.papers
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        getPapers: getPapers,
    },
    dispatch,
)

PapersListPage = connect(mapStateToProps, mapDispatchToProps)(PapersListPage)

export default PapersListPage;
