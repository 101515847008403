import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {getCurrentUser} from "../../redux-actions/auth";
import {connect} from "react-redux";
import BasicLayout from "../../views/MainLayout";
import Documents from "../../lists/Documents";

class DocumentsPage extends Component {
    componentDidMount() {
        this.props.getCurrentUser()
    }

    render() {
        const {user} = this.props

        return (
            <BasicLayout>
                <Documents user={user}/>
            </BasicLayout>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    user: state.auth.user,
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        getCurrentUser,
    },
    dispatch,
)


DocumentsPage = connect(mapStateToProps, mapDispatchToProps)(DocumentsPage)
export default DocumentsPage
