import React from 'react';
import {Button, Card, Col, Row, Spin, Tooltip} from "antd";
import _ from 'lodash-es'
import styles from './Abstract.module.scss'
import {RollbackOutlined} from "@ant-design/icons";
import history from "../services/history";
import {AuhtorsList} from "./AuthorsList";


const ResponseAction = ({abstract}) => {
    return (
            <Tooltip placement='bottom' title='Respond to the review'>
                <Button type="primary" icon={<RollbackOutlined />} onClick={() => history.push(  `/abstracts/${abstract.id}/resubmit`)}>Resubmit</Button>
            </Tooltip>
    )
}

const columnSizes = {
    header: {
        xs: 24,
        md: 6,
        xl: 2
    },
    data: {
        xs: 24,
        md: 18,
        xl: 22
    }

};

const abstractActions = (abstract) => {
    if(['CONDITIONALLY_ACCEPTED', 'REVISE_REQUIRED'].includes((abstract.status))){
        return [<ResponseAction abstract={abstract}/>]
    }
    return null;
}

const AbstractView = ({abstract, sessionTitle, extraButtons} ) =>  {
        let keywords = '';
        if (!_.isEmpty(abstract)) {
            keywords = _.get(abstract, 'keywords', []).join(', ');
        }

        const method = abstract.method === 'in-person' ? 'In-Person' : 'Online';
        const type = abstract.type === 'oral'? "Oral" : 'Poster';

        return (
          <div>
              <Card
                  title="Abstract"
                  extra={extraButtons}
                  actions={abstractActions(abstract)}
              >
                  <Spin spinning={_.isEmpty(abstract)}>
                      <Row gutter={16}>
                          <Col className={styles.header} {...columnSizes.header}>
                              <div>Title:</div>
                          </Col>
                          <Col {...columnSizes.data}>
                              <div>{_.get(abstract, 'title', '')}</div>
                          </Col>
                      </Row>
                      <Row gutter={16}>
                          <Col className={styles.header} {...columnSizes.header}>
                              <div>Authors:</div>
                          </Col>
                          <Col {...columnSizes.data}>
                              <div><AuhtorsList authors={abstract.authors} submitter={abstract.owner} speakerNumber={abstract.presenter}/></div>
                          </Col>
                      </Row>
                      <Row gutter={16}>
                          <Col className={styles.header} {...columnSizes.header}>
                              <div>Keywords:</div>
                          </Col>
                          <Col {...columnSizes.data}>
                              <div>{keywords}</div>
                          </Col>
                      </Row>
                      <Row gutter={16}>
                          <Col className={styles.header} {...columnSizes.header}>
                              <div>Type:</div>
                          </Col>
                          <Col {...columnSizes.data}>
                              <div>{type}</div>
                          </Col>
                      </Row>
                      <Row gutter={16}>
                          <Col className={styles.header} {...columnSizes.header}>
                              <div>Method:</div>
                          </Col>
                          <Col {...columnSizes.data}>
                              <div>{method}</div>
                          </Col>
                      </Row>
                      <Row gutter={16}>
                          <Col className={styles.header} {...columnSizes.header}>
                              <div>Full paper:</div>
                          </Col>
                          <Col {...columnSizes.data}>
                              <div>{abstract.full === 'yes' ? 'Yes' : 'No'}</div>
                          </Col>
                      </Row>
                      <Row gutter={16}>
                          <Col className={styles.header} {...columnSizes.header}>
                              <div>Session:</div>
                          </Col>
                          <Col {...columnSizes.data}>
                              <div>{sessionTitle}</div>
                          </Col>
                      </Row>
                      <Row gutter={16}>
                          <Col className={styles.header} {...columnSizes.header}>
                              <div>Abstract:</div>
                          </Col>
                          <Col {...columnSizes.data}>
                              <div style={{whiteSpace: "pre-wrap"}}>{abstract.content}</div>
                          </Col>
                      </Row>
                  </Spin>
              </Card>

          </div>
        );
}


export default AbstractView
